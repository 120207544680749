@import 'fonts/inter';
@import 'fonts/material-icons';
@import './colors';
@import './theme';
@import '@vi-succ/design-system-web/styles/dsw.css';

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    height: 100%;
    background-color: $color-primary-light;
}

a {
    color: $color-black;
    font-weight: 600;
    text-decoration: none;
}

.main-content {
    padding: 20px 0 !important;
}

.mat-mdc-tooltip.tooltip-large {
    font-size: 0.8em;
}

.cdk-overlay-container .mat-mdc-dialog-container {
    padding: 0;

    vi-quest-hint-dialog {
        .mat-mdc-dialog-content {
            margin: 0;
            padding: 16px;
        }

        .mat-mdc-dialog-actions {
            display: none;
        }

        a {
            text-decoration: underline;
        }
    }
}

a.mat-mdc-raised-button,
button.mat-mdc-raised-button {
    &:hover {
        background-color: $color-primary-light;

        &.mat-primary {
            background-color: $color-primary-grey;
        }
    }
}

a.mat-mdc-button,
a.mat-mdc-raised-button,
button.mat-mdc-button,
button.mat-mdc-raised-button {
    border-radius: 160px;
    padding: 0 20px;
}

input.mat-mdc-input-element::-webkit-outer-spin-button,
input.mat-input-element::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

mat-table {
    .mat-mdc-cell,
    .mat-mdc-header-cell {
        padding: 0;
    }

    .mat-mdc-header-cell {
        color: $color-primary-medium;
    }
}

vi-quest-hint {
    position: absolute;
    padding-left: 4px;

    &:hover {
        cursor: pointer;
    }

    &.shown {
        mat-icon {
            color: $color-black;
            opacity: 1;
        }
    }

    mat-icon:hover {
        color: $color-black !important;
        opacity: 1;
    }

    .mat-mdc-card {
        font-size: 14px;
        background: $color-primary-lighter;
        min-width: 200px;
        position: relative;
        left: -100px;
        box-shadow: 0px 2px 1px -1px rgba($color-black, 0.2), 0px 1px 1px 0px rgba($color-black, 0.14),
            0px 1px 3px 0px rgba($color-black, 0.12) !important;
        margin: 4px !important;
        flex: 1 1 0%;

        &.selected {
            background: $color-primary-light;
            border: 2px solid rgba($color-black, 0.5);
        }
    }
}

/* Firefox */
input.mat-mdc-input-element[type='number'] {
    -moz-appearance: textfield;
}

// Angular 17 Changes

// Icon

mat-icon {
    overflow: unset !important;
    &.mat-error {
        color: $color-error;
    }
}

// Global Buttons

.mdc-button,
.mat-mdc-button {
    height: 40px !important;
    padding: 0 20px !important;
    line-height: 40px !important;
    > .mat-icon {
        margin: 0 4px 0 0 !important;
        width: 24px !important;
        height: 24px !important;
        font-size: 24px !important;
        line-height: 24px !important;
    }
    .mat-mdc-button-touch-target {
        height: 100% !important;
    }
}

.mat-mdc-icon-button {
    &.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 40px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
    }
    .mat-mdc-button-touch-target {
        width: 40px !important;
        height: 40px !important;
    }
    .mat-mdc-button-ripple,
    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-persistent-ripple::before {
        display: none !important;
    }
}

// Chips

.mdc-evolution-chip-set {
    .mdc-evolution-chip {
        margin-left: 0 !important;
    }
    .mdc-evolution-chip-set__chips {
        margin-left: 0 !important;
    }
}

.mat-mdc-standard-chip {
    width: 100% !important;
    &.mdc-evolution-chip--with-primary-graphic {
        .mdc-evolution-chip__graphic {
            display: none !important;
        }
    }
    .mdc-evolution-chip__action--primary {
        width: 100%;
        padding: 0 12px !important;
    }
    .mdc-evolution-chip__text-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-weight: 600;
    }
}

.mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-container-height: 40px !important;
}

// Form Fields

// Textfield

.mdc-form-field {
    > label {
        padding-left: 8px !important;
        font-size: 16px !important;
    }
}

.mdc-text-field {
    padding: 0 !important;
}

.mat-mdc-form-field,
.mat-mdc-select {
    font-size: 16px !important;
}

.mat-mdc-form-field-infix {
    padding: 16px 0 4px 0 !important;
    min-height: unset !important;
}

.mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
        .mat-mdc-floating-label {
            top: 28px !important;
            font-size: 16px !important;
        }
    }
}

.mdc-text-field--filled {
    background: transparent !important;
    .mat-mdc-form-field-focus-overlay {
        display: none !important;
    }
    &:not(.mdc-text-field--disabled) {
        .mdc-floating-label,
        .mdc-floating-label--float-above {
            top: 20px !important;
        }
    }
    &.mdc-text-field--invalid {
        &:not(.mdc-text-field--disabled) {
            &.mdc-text-field--focused {
                .mdc-floating-label,
                .mdc-floating-label--float-above {
                    top: 20px !important;
                }
            }
        }
    }
    &.mdc-text-field--invalid {
        &:not(.mdc-text-field--disabled) {
            .mdc-floating-label,
            .mdc-floating-label--float-above {
                top: 28px !important;
            }
        }
    }
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
    > .mat-icon {
        padding: 0 !important;
    }
}

// Radio

.mat-mdc-radio-button {
    padding: 0 !important;
    .mdc-radio {
        padding: 0 !important;
    }
}

// Checkbox

.mat-mdc-list-item {
    &.mdc-list-item--with-leading-avatar,
    &.mdc-list-item--with-leading-checkbox,
    &.mdc-list-item--with-leading-icon {
        &.mdc-list-item--with-one-line {
            padding: 0 !important;
            height: auto !important;
        }
    }
}

.mdc-list-item--with-leading-checkbox {
    .mdc-list-item__start {
        margin: 0 0 0 -8px !important;
    }
}

.mdc-list-item__primary-text {
    font-size: 16px !important;
}

.mdc-list {
    margin: 0 !important;
}

.mdc-list-item--with-leading-checkbox {
    .mdc-list-item__start {
        margin: 0;
    }
}

.mat-mdc-list-option {
    .mdc-checkbox {
        padding: 8px !important;
    }
}

.mat-mdc-list-option {
    .mdc-checkbox {
        .mdc-checkbox__background {
            top: 8px !important;
            left: 8px !important;
        }
    }
}

.mdc-list-item {
    &:hover {
        &::before {
            display: none !important;
        }
    }
}

// Select

.mat-mdc-option {
    box-sizing: border-box !important;
}

// Skeleton

.skeleton-loader {
    margin-bottom: 0 !important;
}

// Dialog

.mat-mdc-dialog-container {
    .mdc-dialog__surface {
        padding: 24px;
    }
}

.mdc-dialog__title {
    padding: 0 0 8px 0 !important;
    font-weight: 700 !important;
    &::before {
        display: none !important;
    }
}

.mat-mdc-dialog-container {
    .mdc-dialog__content {
        margin-right: -24px;
        padding: 0 24px 24px 0 !important;
        font-family: inherit !important;
        font-size: inherit !important;
        color: inherit !important;
    }
}

.mat-mdc-dialog-actions {
    padding: 0 !important;
    min-height: unset !important;
}

// Paginator

.mat-mdc-paginator {
    .mat-mdc-form-field-infix {
        padding: 4px 0 !important;
        min-height: auto !important;
    }
    .mdc-text-field {
        padding: 0 16px !important;
    }
}

.mdc-text-field--outlined {
    .mdc-notched-outline {
        > * {
            border-color: map-get($palette-primary, 300) !important;
        }
    }
}

// Badge

.mat-badge-large {
    .mat-badge-content {
        display: none !important;
    }
}

// Snackbar

.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
        padding: 0 !important;
        overflow: hidden;
    }
    .mdc-snackbar__label {
        padding: 0 !important;
    }
}

// Stepper

.mat-stepper-horizontal-line {
    display: none !important;
}

// Tabs

.mat-mdc-tab-header {
    border-bottom: 1px solid $color-primary-light-grey;
}

.mat-mdc-tab {
    .mdc-tab__text-label {
        span {
            font-size: 16px;
            font-weight: 400;
        }
    }
    .mdc-tab-indicator__content--underline {
        border-color: $color-primary-grey !important;
    }
}

input.mat-mdc-input-element::-webkit-outer-spin-button,
input.mat-mdc-input-element::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
